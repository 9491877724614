import * as React from 'react'
import EducationNavigation from "./educationNavigation";
import Image from "../global/image";
import logo from "../../images/musical-theatre-logo.png"

const SchoolMusicalsNavigation = (props) => {
    return (
        <>
            <EducationNavigation active={props.active} />
            <Image src={logo} alt="School Musicals Logo" className="sidebar-logo" />
        </>
    )
}

export default SchoolMusicalsNavigation