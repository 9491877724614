import * as React from "react"
import {graphql} from "gatsby";
import HeroImage from "../../components/global/heroImage";
import InnerLayout from "../../components/global/innerLayout"
import PageTitle from "../../components/global/pageTitle";
import parse from "html-react-parser";
import SchoolMusicalsNavigation from "../../components/education/schoolMusicalsNavigation";

export const query = graphql`
    query($id: [Craft_QueryArgument]) {
      craft {
        page: entry(section: "schoolMusicalsPage", id: $id) {
          ... on Craft_schoolMusicalsPage_default_Entry {
          seomatic {
              metaTitleContainer
              metaTagContainer
              metaLinkContainer
              metaScriptContainer
              metaJsonLdContainer
              metaSiteVarsContainer
              frontendTemplateContainer
            }
            id
            uri
            title
            contentDefaultHtmlGeneral
            educationHeroImage {
              ... on Craft_educationHeroImage_image_BlockType {
                image {
                  url(transform: "heroImageTransform")
                  ... on Craft_websiteEducationGeneral_Asset {
                     altText
                  }
                }
                imageLink {
                  linkUrl
                  openInNewTab
                }
              }
            }
          }
        }
      }
    }
  `

const HumaneHeroesBasicPage = ({ data, pageContext }) => {

    return (
        <InnerLayout seomatic={data.craft.page.seomatic} entryId={data.craft.page.id}>
            <div className="midd school-musicals-basic-page">

                <PageTitle title={data.craft.page.title} className="school-musicals" />
                <HeroImage image={data.craft.page.educationHeroImage} />

                <div className="content-page">
                    <div className="container-small">

                        <div className="content-colum-row">
                            <div className="sidebar">
                                <SchoolMusicalsNavigation active={data.craft.page.uri}/>
                            </div>
                            <div className="content-right">

                                {parse(data.craft.page.contentDefaultHtmlGeneral)}

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </InnerLayout>
    )
}

export default HumaneHeroesBasicPage
